import {Inject, Injectable} from '@angular/core';
import {CallbackDialogComponent} from "@app/shared/components/callback/callback-dialog.component";
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {fromEvent} from "rxjs";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {DOCUMENT} from "@angular/common";
import {Flags} from "@app/shared/models/flag/flag.model";

@Injectable({
  providedIn: 'root'
})
export class ExitPopupService {

  _matDialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog,
              private storeService: CalculationStoreService,
              private featureFlagService: FeatureFlagService,
              @Inject(DOCUMENT) private document: Document) {
  }

  public init() {
    setTimeout(() => {
      if (this.featureFlagService.isActive(Flags.FLAG_934_EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER)) {
        this.handleMouseLeave();
      }
    }, 15000);
  }

  private handleMouseLeave() {
    const el = document.querySelectorAll('body');

    fromEvent(el, 'mouseleave').subscribe((e: MouseEvent) => {
      if (e.pageY < 0 &&
          !this._matDialogRef &&
          !this.dialog.openDialogs.length &&
          !(this.isCalculationSent() || this.isCallbackSent() || this.isSellPolicySent())) {
        this.openCallbackDialog();
      }
    });
  }

  private openCallbackDialog() {
    this._matDialogRef = this.dialog.open(CallbackDialogComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: 'callback-dialog',
      data: {
        closeDialogCallback: () => this.dialog.closeAll(),
        errorCode: 'exit-popup-mouse-leave-top-border'
      },
    });
  }

  private isCalculationSent() {
    const savedCalculationForm = this.storeService.getSavedCalculationForm();

    if (savedCalculationForm?.vehicleOwnerDetails.firstName &&
        savedCalculationForm?.vehicleOwnerDetails.lastName &&
        savedCalculationForm?.vehicleOwnerDetails.phoneNumber) {
      return true;
    }

    return false;
  }

  private isCallbackSent() {
    return this.storeService.getSavedCallbackForm() !== null;
  }

  private isSellPolicySent() {
    return this.storeService.isPolicySold();
  }

}
